// All Blogs
export const TermsAndConditionsContent = {
  title: `Terms and <span class="text-[#B695F8]">Conditions</span>`,
  description:
    "Welcome to Synkli, we are delighted to have you in our digital community. We have provided these terms and conditions to make the usage of our brand a seamless one.",
  image: {
    path: "circled-pattern",
    alt: "circled-pattern",
  },
  pageContent: [
    {
      title: "Introduction",
      titleEC: "!mt-0",
      description: `Welcome to Synkli, we are delighted to have you in our digital community. We have provided these terms and conditions to make the usage of our brand a seamless one. We are proud to adhere to the laws and regulations of South Australia when crafting our Terms of Use. Ensuring compliance with South Australian laws is integral to our commitment to transparency, fairness, and legal responsibility. As you engage with our platform, rest assured that our Terms of Use reflect our dedication to upholding the highest legal standards and protecting the rights of our users. As you navigate our website and utilise our services, you must familiarise yourself with our Terms of Use. These terms outline the guidelines and regulations governing your interactions with our platform, ensuring a seamless and secure user experience. At Synkli, we prioritise transparency, integrity, and respect for our users’ rights. By accessing or using our website, you agree to comply with these terms and conditions, which protect you and our organisation.
      <br><br>
      Please note that our Terms of Use are subject to change at any time without prior notice. We reserve the right to modify, update, or amend these terms as necessary to reflect changes in our business practices, legal requirements, or industry standards. By accessing or using our website, you agree to comply with the most recent version of our Terms of Use, as posted on our platform. We encourage you to read our Terms of Use carefully to understand your rights and responsibilities while engaging with our platform. Thank you for choosing Synkli as your trusted digital partner.`,
    },
    {
      title: "Registering and Using Synkli",
      description: `Registering and using Synkli under the terms of use signifies your acknowledgment and acceptance of our platform’s guidelines and regulations. By creating an account or accessing our services, you agree to provide accurate and up-to-date information, including your contact details and any other required information during registration.
      <br><br>
      As a user of Synkli, you are responsible for maintaining the confidentiality of your account credentials and all activities under your account. You agree to notify us immediately of any unauthorised access or use of your account to ensure the security of your personal information and the integrity of our platform.
      Furthermore, while using Synkli, you agree to comply with all applicable laws, regulations, and our community standards. You acknowledge that any content you upload, share, or interact with on our platform must not infringe upon the rights of others, including intellectual property rights, privacy rights, or any other legal rights.
      <br><br>
      Synkli reserves the right to suspend or terminate your account without prior notice if we believe you have violated our Terms of Use or engaged in any activity that may harm our platform or other users.
      <br><br>
      By registering and using Synkli, you consent to receive communications from us regarding important updates, announcements, or other relevant information related to your account and our services.`,
    },
    {
      title: "Your Responsibilities",
      description: `As a subscriber and user of Synkli, you are responsible for ensuring a safe, respectful, and constructive online environment for yourself and others. First and foremost, you are responsible for using our platform by our Terms of Use and all applicable laws and regulations. This entails conducting yourself in a manner that upholds our community’s integrity and respects fellow users’ rights and well-being.
      <br><br>
      Your responsibilities include maintaining the confidentiality and security of your account credentials. You must take proactive measures to safeguard your login information and prevent unauthorised access to your account. Should you become aware of any suspicious activity or unauthorised use of your account, you must promptly notify us to mitigate potential risks and protect your personal information.
      <br><br>
      Furthermore, as a subscriber and user, you are accountable for the content you create, share, or interact with on Synkli. You must ensure that any content you contribute complies with our community guidelines and does not violate the rights of others, including intellectual property rights, privacy rights, or any applicable laws. Upholding these standards fosters a positive and inclusive digital environment where users can interact and exchange valuable insights and information.
      <br><br>
      It is also your responsibility to refrain from engaging in any conduct that may disrupt or impair our platform’s functionality or interfere with others’ user experience. This includes refraining from spamming, hacking, or distributing malicious software. By respecting the integrity of our platform and its users, you contribute to our digital community’s overall quality and reliability.
      <br><br>
      As a subscriber and user of Synkli, you play an integral role in shaping the collective experience of our platform. By embracing your responsibilities and adhering to our guidelines, you help foster a collaborative and enriching environment where individuals can connect, learn, and thrive.`,
    },
    {
      title: "Subscription Fees: Free and Paid",
      description: `Synkli offers free and paid subscription options to cater to the diverse needs of our users. Our free subscription provides access to basic features and services, allowing users to explore our platform and experience its functionality without any upfront cost. This option is ideal for individuals new to our platform or with minimal usage requirements.
      <br><br>
      For users seeking additional features and premium services, we offer paid subscription plans with enhanced functionalities and exclusive benefits. Our paid subscriptions are designed to provide greater flexibility, customisation, and access to advanced tools and resources tailored to meet the evolving needs of our users. By subscribing to a paid plan, users can unlock premium features and enjoy an enhanced user experience, ensuring greater productivity and satisfaction. In addition to our standard subscription options, Synkli may offer trial subscriptions to allow users to experience the full range of features and benefits with our paid plans. Trial subscriptions typically provide temporary access to premium features for a limited period, enabling users to evaluate the value and suitability of our paid services before making a long-term commitment.
      <br><br>
      It is important to note that subscription fees may be subject to applicable taxes and fees based on your location and the prevailing tax regulations in your jurisdiction. These additional taxes and fees may vary depending on your geographic location, the type of subscription plan selected, and any relevant local tax laws or regulations. Synkli is committed to transparency and fairness in our pricing and subscription policies. We strive to provide clear and accurate information regarding subscription fees, taxes, and applicable terms and conditions to ensure our users can make informed decisions about their subscription options. We value our users’ trust and confidence in us, and we are dedicated to delivering exceptional value and service through our subscription offerings.`,
    },
    {
      title: "Ownership, Copyrights and Trademarks",
      description: `Ownership, Copyrights and Trademarks
      User content encompasses any materials, information, or data that users create, upload, submit, or otherwise contribute to Synkli’s platform. This may include, but is not limited to, personal information, text, images, videos, audio recordings, documents, and any other content shared or transmitted through our services.
      <br><br>
      It’s essential to clarify that users retain ownership and intellectual property rights to the content they create and share on our platform. By uploading or contributing user content, users grant Synkli a non-exclusive, worldwide, royalty-free licence to use, reproduce, distribute, modify, adapt, publicly display, and perform the content solely to operate, promote, and improve services. This licence allows us to facilitate the sharing and dissemination of user-generated content within our community and provide our platform’s functionalities and features.
      <br><br>
      Synkli respects the intellectual property rights of users and third parties. Users are responsible for ensuring that the content they upload or share does not infringe upon the copyrights, trademarks, or any other intellectual property rights of others. Users should only upload or share content that they have the necessary rights or permissions to use, and they should refrain from infringing upon the intellectual property rights of others.
      <br><br>
      In certain circumstances, Synkli may need to access or utilise user content for specific purposes, such as:`,
      list: [
        `<span style="font-weight: 600;">Moderation and Compliance:</span> To enforce our community guidelines, terms of use, and applicable laws and regulations, we may need to review user content to identify and address violations or inappropriate behaviour.`,
        `<span style="font-weight: 600;">Improving and Developing Services:</span> We may analyse user content to gain insights into user preferences, trends, and usage patterns, which helps us enhance and optimise our platform’s features and functionalities. Technical Support and Troubleshooting: Users may provide user content as part of seeking technical support or assistance with troubleshooting issues related to our platform or services.`,
        `<span style="font-weight: 600;">Legal and Compliance Obligations:</span> In response to legal requests, court orders, or legal processes, we may be required to disclose or provide access to user content to comply with applicable laws and regulations or to protect our rights, property, or safety, as well as the rights, property, or safety of our users or others. Users should review our Privacy Policy for more information on how we collect, use, and disclose user content and personal information. Using our platform and services, users consent to the collection, processing, and use of their user content as described in our Privacy Policy and in accordance with applicable laws and regulations.`,
      ],
    },
    {
      title: "Limited Access to User Content",
      description: `Limited access to user content is a fundamental aspect of Synkli’s platform, ensuring the privacy and security of our users’ data. While users retain ownership and control over their content, certain restrictions are in place to regulate access and usage. Our platform is designed to provide limited access to user content, ensuring that only authorised individuals or entities can view or interact with it. This limited access protects the confidentiality and integrity of user data, preventing unauthorised users from accessing or misusing sensitive information. Users can control who has access to their content by adjusting privacy settings and permissions, allowing them to share their content selectively with specific individuals or groups.
      <br><br>
      We employ robust mechanisms to restrict access to user content, prioritising adherence to South Australian privacy laws and ensuring users retain control over their data. Through granular privacy settings, users can specify the visibility of their content, determining who can access it within the platform. These settings allow users to tailor their privacy preferences based on their comfort level, enabling them to share content exclusively with trusted connections or a wider audience.
      <br><br>
      Furthermore, Synkli implements stringent access controls and authentication measures to safeguard user content. Only authorised individuals with valid credentials are granted access to user data, and strict authentication protocols, including multi-factor authentication, bolster security. By adhering to these practices, Synkli ensures compliance with South Australian privacy laws and empowers users to maintain control over their data, thereby fostering trust and confidence in the platform.`,
    },
    {
      title: "Confidential Information",
      description: `Confidential information plays a crucial role in maintaining the integrity and security of both users and Synkli. Confidential information refers to any sensitive or proprietary data disclosed or shared between users and our company, either through our platform or other communication channels. This may include but is not limited to, personal information, business strategies, trade secrets, financial data, and any other information that is not intended for public disclosure. At Synkli, we comply with the South Australian Privacy Act 1988.
      <br><br>
      Users are responsible for safeguarding confidential information shared through our platform. This includes exercising caution when sharing sensitive data with other users, ensuring access to confidential information is limited to authorised individuals, and complying with confidentiality agreements or obligations that may apply to the information shared.
      <br><br>
      As required by the APP 11i, we take active measures to protect the confidentiality of user information and respect the privacy rights of our users. We have implemented robust security measures and protocols to safeguard confidential information from unauthorised access, disclosure, or misuse. Our privacy practices are designed to ensure compliance with applicable data protection laws and regulations, and we continuously strive to uphold the highest standards of data security and privacy protection.
      <br><br>
      Users need to understand that while we take all reasonable precautions to protect confidential information, no method of transmission over the Internet or electronic storage is completely secure. Users should exercise caution when sharing sensitive information online and take appropriate measures to protect their confidential information.
      <br><br>
      While providing our services, Synkli may also access and handle confidential information from users. We are committed to maintaining the confidentiality of user data and only accessing or using confidential information for legitimate business purposes and by our privacy policy and applicable laws and regulations.
      <br><br>
      Any unauthorised access, use, or disclosure of confidential information by users or our company is strictly prohibited and may result in legal consequences. By using our platform and services, users acknowledge and agree to abide by the confidentiality provisions outlined in our Terms of Use and Privacy Policy to protect confidential information for all parties involved.`,
    },
    {
      title: "Security",
      description: `Security is a paramount concern for both users and Synkli, and we are dedicated to maintaining a safe and secure environment for all interactions on our platform. We employ state-of-the-art security measures to protect user data, prevent unauthorised access, and mitigate potential privacy and information security risks.
      <br><br>
      One of the key aspects of our security measures is encryption. We utilise industry-standard encryption protocols to secure data transmission and communication between users and our platform. This helps to safeguard sensitive information from interception or unauthorised access by third parties.
      <br><br>
      Additionally, we employ robust authentication mechanisms to verify the identity of users and prevent unauthorised access to user accounts. This includes the implementation of multi-factor authentication and other authentication techniques to ensure that only authorised individuals can access user accounts and sensitive data.
      <br><br>
      Our platform is also equipped with advanced security features such as firewalls, intrusion detection systems, and regular security audits to detect and respond to potential security threats in real time. We continuously monitor our systems for any suspicious activity and take immediate action to address any security vulnerabilities or breaches.
      <br><br>
      Furthermore, we are committed to compliance with relevant data protection laws and regulations, including the General Data Protection Regulation (GDPR) and other applicable privacy laws. We have implemented comprehensive privacy policies and procedures to ensure user data is collected, processed, and stored in compliance with legal requirements and industry best practices. The security measures employed by Synkli to protect user data and ensure compliance with South Australian data protection laws include:`,
      list: [
        `<span style="font-weight: 600;">Encryption protocols:</span> Synkli utilises industry-standard encryption protocols to secure data transmission and communication between users and the platform. This ensures that sensitive information is safeguarded from interception or unauthorised access by third parties.`,
        `<span style="font-weight: 600;">Authentication mechanisms:</span> Robust authentication mechanisms are implemented to verify the identity of users and prevent unauthorised access to user accounts. This includes multi-factor authentication and other techniques to ensure that only authorised individuals can access sensitive data.`,
        `<span style="font-weight: 600;">Advanced security features:</span> The platform has advanced security features such as firewalls, intrusion detection systems, and regular security audits. These measures help to detect and respond to potential security threats in real-time, ensuring the ongoing protection of user data.`,
        `<span style="font-weight: 600;">Compliance with data protection laws:</span> Synkli is committed to compliance with relevant data protection laws and regulations, including South Australian data protection laws. Comprehensive privacy policies and procedures are implemented to ensure user data is collected, processed, and stored in compliance with legal requirements and industry best practices.`,
        `<span style="font-weight: 600;">Privacy awareness:</span> Synkli promotes a culture of security and privacy awareness among users and employees. This includes guiding you in choosing strong passwords, being cautious about sharing personal information online and keeping devices and software up-to-date with the latest security patches and updates.`,
      ],
    },
    {
      description: `While we take all reasonable precautions to protect user data and ensure the security of our platform, it is important for users also to take proactive measures to safeguard their information. This includes choosing strong and unique passwords, being cautious about sharing personal information online and keeping their devices and software up-to-date with the latest security patches and updates. At Synkli, we are committed to fostering a culture of security and privacy awareness among our users and employees. We believe that working together and remaining vigilant can create a safer and more secure online environment for everyone.`,
    },
    {
      title: "Users Conduct",
      description: `As you navigate our services, it is important to acquaint yourself with our platform’s guidelines and regulations, ensuring a seamless and enriching experience for all. While our platform may not facilitate direct interactions between users like other social networks, your conduct and adherence to our policies are integral to fostering a secure and productive environment for everyone. At Synkli, we prioritise integrity, respect, and responsible engagement, and we look forward to your active participation in our digital community.`,
      list: [
        `<span style="font-weight: 600;">Compliance with Terms of Use:</span> Users should familiarise themselves with and adhere to the platform’s Terms of Use. This includes following any rules or guidelines Synkli sets regarding user behaviour and content sharing.`,
        `<span style="font-weight: 600;">Responsible Content Sharing:</span> Users should ensure that any content they upload or share on the platform is appropriate and complies with its guidelines. This includes refraining from sharing illegal, offensive, or inappropriate content that may violate the platform’s policies.`,
        `<span style="font-weight: 600;">Respect for Intellectual Property:</span> Users should respect the intellectual property rights of others and refrain from infringing on copyrights, trademarks, or any other intellectual property rights in their content-sharing activities.`,
        `<span style="font-weight: 600;">Security Awareness:</span> Users should protect their security and privacy while using the platform. This includes safeguarding their account credentials, being cautious about sharing personal information, and being vigilant against potential security threats.`,
        `<span style="font-weight: 600;">Ethical Behaviour:</span> Users should conduct themselves ethically and professionally while using the platform. This includes refraining from engaging in any form of dishonesty, fraud, or deceptive practices.`,
        `<span style="font-weight: 600;">Feedback and Reporting:</span> Users should provide constructive feedback to Synkli regarding any issues or concerns they encounter on the platform. Additionally, users should report any violations of the platform guidelines or Terms of Use to the appropriate authorities for investigation and resolution. It is important that all users follow South Australian laws and regulations when using our digital platform. By following our guidelines for user conduct, users can help maintain a safe, respectful, and positive environment on the Synkli platform, even without direct interactions with other users.`,
      ],
    },
    {
      title: "No Responsibility For Third-Party Services",
      description: `No Responsibility For Third-Party Services
      Synkli strives to provide a comprehensive and enriching user experience by offering access to various third-party sites or resources through our platform. However, users need to understand that we are not responsible for these third-party sites’ content, services, or practices. While we may provide links, advertisements, or integrations to third-party sites for our users’ convenience and benefit, including such links does not imply endorsement or approval by Synkli. Users should exercise caution and discretion when accessing or interacting with third-party sites and review their respective terms of use and privacy policies.
      <br><br>
      It is important to note that Synkli does not control or influence third-party sites’ content, availability, or security. We cannot guarantee the accuracy, reliability, or legality of information or services provided by third parties. We cannot be held liable for any damages, losses, or disputes arising from interactions with third-party sites.
      <br><br>
      Users access third-party sites at their own risk and discretion. Any transactions, interactions, or agreements entered into with third parties are solely between the user and the third party, and Synkli is not a party to such transactions or agreements.
      <br><br>
      We encourage users to exercise caution and conduct due diligence when accessing third-party sites or using third-party services. If users encounter any issues or concerns with third-party sites accessed through our platform, they should direct their inquiries or complaints to the respective third parties.
      <br><br>
      By accessing or using our platform, users acknowledge and agree that Synkli shall not be held responsible or liable for any damages, losses, or disputes arising from interactions with third-party sites or resources. Our priority is to provide a safe and reliable platform for our users, and we appreciate your understanding and cooperation in this matter.`,
    },
    {
      title: "Data Protection and Liability Disclaimer",
      description: `While Synkli employs advanced technologies and security measures to safeguard the personal information provided by users on our platform, it is essential to acknowledge the inherent risks associated with data handling in the digital era. Despite our best efforts to maintain the integrity and confidentiality of user data, unforeseen events such as hacking, server corruption, or other technological vulnerabilities may occur, leading to potential data breaches or leaks.
      <br><br>
      Therefore, users should understand and accept that while Synkli is committed to protecting their personal information, we cannot guarantee absolute immunity against all possible security threats or breaches. In the event of a data breach or leak due to circumstances beyond our control, Synkli shall not be held liable for any resulting damages or losses incurred by users.
      <br><br>
      By agreeing to our Terms of Use and utilising our platform, users acknowledge and accept the aforementioned risks associated with data protection in the digital environment. While Synkli will make every effort to address and mitigate such risks, users are encouraged to exercise caution and employ additional security measures to safeguard their personal information.`,
    },
    {
      title: "Termination of Subscriptions",
      description: `At Synkli, we understand that circumstances may arise where users need to terminate their subscriptions. We aim to make this process as straightforward and transparent as possible while ensuring compliance with our Terms of Use and applicable laws. Synkli follows the procedures laid down by The Australian Competition and Consumer Commission. Our subscription cancellation process is simple and easy; users can delete their given data if they want. We are against unfair or difficult subscription termination. Therefore, you can go through these important tips to terminate your subscription.`,
      list: [
        `<span style="font-weight: 600;">Voluntary Termination:</span> Users can terminate their subscriptions anytime by following the procedures outlined in their account settings or contact our customer support team. We respect the autonomy of our users and strive to facilitate a smooth and hassle-free termination process. Cancellation Policy: Depending on the subscription agreement terms, users may be subject to a cancellation fee or other conditions upon terminating their subscription. These details are typically outlined in the subscription agreement or Terms of Use, and users are encouraged to review these documents carefully before proceeding with termination.`,
        `<span style="font-weight: 600;">Procedures for Termination:</span> Users may be required to follow specific procedures or provide certain information to complete the termination process. This could include verifying their identity, confirming their intent to terminate the subscription, or providing feedback on their experience with our platform.`,
        `<span style="font-weight: 600;">Refund Policy:</span> Depending on the circumstances of the termination, users may be entitled to a refund of any unused portion of their subscription fees. Our refund policy outlines the conditions under which refunds are issued and the process for requesting a refund. Users should review the refund policy carefully to understand their rights and obligations regarding refunds.`,
        `<span style="font-weight: 600;">Effect of Termination:</span> Upon termination of a subscription, users may lose access to certain features or functionalities of our platform, depending on the terms of the subscription agreement. Any data or content associated with the subscription may also be subject to deletion or removal in accordance with our data retention policies.`,
        `<span style="font-weight: 600;">Communication of Termination:</span> We are committed to communicating effectively with users throughout the termination process. This includes providing clear and timely notifications regarding their subscription status, any applicable fees or penalties, and the steps required to complete the termination process.`,
      ],
    },
    {
      description: "At Synkli, we value the trust and loyalty of our users and strive to uphold the highest standards of customer service and satisfaction. If users have questions or concerns about terminating their subscriptions, they are encouraged to contact our customer support team for assistance. We are here to help make the termination process as smooth and stress-free as possible for our users.",
    },
    {
      title: "Following up",
      description: `At Synkli, we understand the importance of ensuring a seamless user experience from start to finish. If a user stops at the stage of considering or selecting a product, we may employ follow-up email strategies to provide additional assistance and support. Our follow-up emails are designed to be informative, helpful, and non-intrusive to re-engage users and address any concerns or questions they may have. These emails may include:`,
      list: [
        `<span style="font-weight: 600;">Product Recommendations:</span> We may suggest alternative products or services that align with users’ preferences or needs based on their previous interactions on our platform.`,
        `<span style="font-weight: 600;">Assistance and Support:</span> We offer assistance and support to users who may have encountered issues or obstacles when purchasing. Our customer support team can address any questions or concerns and provide personalised assistance.`,
        `<span style="font-weight: 600;">Promotional Offers:</span> We may provide users exclusive discounts, promotions, or incentives to encourage them to complete their purchases and take advantage of special offers available on our platform.`,
        `<span style="font-weight: 600;">Feedback Requests:</span> We value user feedback and may contact users to solicit their input on their experience with our platform and products. This feedback helps us identify areas for improvement and enhance the user experience.`,
        `<span style="font-weight: 600;">Educational Resources:</span> We may provide users with educational resources or informative content related to the product or service they were considering, helping them make informed decisions and understand the value proposition.`,
        `<span style="font-weight: 600;">Re-engagement Campaigns:</span> We may implement re-engagement campaigns for users who have not responded to initial follow-up emails to reignite their interest and encourage them to revisit our platform.`,
      ],
    },
    {
      description: `It is important to note that our follow-up email strategies are implemented in compliance with applicable laws and regulations, including anti-spam laws such as the CAN-SPAM Act. Users can unsubscribe from email communications anytime, and we respect their preferences and privacy choices. By employing effective follow-up email strategies, we strive to provide users with the support and assistance they need to make informed decisions and ultimately enhance their overall experience with Synkli.`,
    },
    {
      title: "Modification of License and Site Offerings",
      description: `At Synkli, we reserve the right to modify or terminate licences and site offerings in accordance with our Terms of Use. This ensures our platform’s continued improvement, security, and viability for all users.`,
      list: [
        `<span style="font-weight: 600;">Product Recommendations:</span> We may suggest alternative products or services that align with users’ preferences or needs based on their previous interactions on our platform.`,
        `Licence Modification: We may modify the terms of licences granted to users for accessing and using our platform. This could include changes to the scope of permitted usage, features, or functionalities available under the licence. Any modifications will be communicated to users in advance, and users will have the opportunity to review and accept the updated terms before continuing to use our platform.`,
        `Site Offerings Modification: We may also modify or update the offerings available on our site, including products, services, features, or content. These modifications may be made in response to changes in user needs, technological advancements, market trends, or other factors. While we strive to minimise disruption to users, we reserve the right to make changes as necessary to improve our platform’s overall user experience and value proposition.`,
        `Termination of Licence: In certain circumstances, we may terminate or suspend licences granted to users for accessing our platform. This could occur if users violate our Terms of Use, engage in illegal or unethical behaviour, or pose a risk to the security or integrity of our platform. Terminating a licence may result in revocation of access to certain features or functionalities of our platform and suspension or closure of user accounts.`,
        `Communication of Changes: We are committed to transparency and will communicate any modifications to licences or site offerings to users through appropriate channels, such as email notifications, platform announcements, or updates to our Terms of Use. We encourage users to review these communications carefully and contact us with questions or concerns.`,
        `User Consent: By continuing to use our platform after being notified of modifications to licences or site offerings, users implicitly consent to the updated terms and conditions. Users who do not agree with the modifications may choose to discontinue their use of our platform in accordance with the Australian Consumer Law.`,
      ],
    },
    {
      description: `At Synkli, we are committed to maintaining a fair, transparent, and user-centric approach to managing licences and site offerings in accordance with the Australian Consumer Law. We appreciate the trust and support of our users and remain dedicated to providing a valuable and enjoyable experience for all.`,
    },
  ],
}
